.carousel-container {
    position: relative;
    overflow: hidden;
}

.carousel-container::before,
.carousel-container::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50px;
    z-index: 2;
    pointer-events: none;
}
