.red-circle::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
    margin-right: 8px;
    vertical-align: middle;
}

@media (max-width: 1650px) {
    .custom-height {
        height: 50rem;
    }
    .custom-service-height {
        height: 30rem;
    }
    .custom-image-height {
        height: 850px;
    }
}

@media (min-width: 1650px) {
    .custom-height {
        height: 60rem;
    }
    .custom-service-height {
        height: 40rem;
    }
    .custom-image-height {
        height: 900px;
    }
}

@media (max-width: 768px) {
    .custom-top {
        margin-top: 3rem;
    }
}

@media (max-width: 640px) {
    .services-page {
        margin-left: 2.5rem;
    }
}

/* .about__img {
    background: url("./assets/about.png");
    background-size: cover;
} */

.text-typing {
    overflow: hidden; /* Ensures text is hidden initially */
    white-space: nowrap; /* Prevents text wrapping */
    border-right: 2px solid black; /* Adds the cursor */
    animation: typing 4s steps(40, end) 1s forwards, blink 0.5s step-end infinite;
}

@media (min-width: 1920px) {
    .monitor-24\:text-2xl {
        font-size: 1.5rem; /* Tailwind's 2xl size */
        line-height: 2rem;
    }
}

@media screen and (min-width: 1920px) {
    .monitor-24\:h {
        height: calc(15vh); /* Adjust for large screens */
    }
}

@media screen and (min-resolution: 2dppx) {
    .high-dpi-h {
        height: calc(14vh); /* Fine-tune for high-DPI screens */
    }
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(20px); /* Adjust direction if needed */
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.slide-in {
    opacity: 0;
    transform: translateY(20px); /* Initial position */
    transition: opacity 2s ease, transform 0.5s ease;
}

.slide-in.visible {
    opacity: 1;
    transform: translateY(0);
    animation: slideIn 2s ease forwards;
}
.image-format{
    border-radius: 100% 0% 0% 100% / 100% 72% 28% 0% ;
}

.group:hover::after {
    content: '';
    position: absolute;
    bottom: 0; /* Adjust to align at the bottom */
    left: 0;
    width: 100%;
    height: 2px; /* Thickness of the line */
    background-color: #ffffff; /* Change to your preferred color */
}

.groupT:hover::after {
    content: '';
    position: absolute;
    bottom: 0; /* Adjust to align at the bottom */
    left: 0;
    width: 100%;
    height: 2px; /* Thickness of the line */
    background-color: red; /* Change to your preferred color */
}

.swiper-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.swiper-slide {
    position: relative;
    width: 100%;
    height: 100vh;
}

/* content */

.content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 14%;
    left: 3%;
    width: 40%;
    height: max-content;
    color: #f2f2f2;
    text-align: center;
    padding: 20px;
    opacity: 0;
    z-index: 2;
}

.content h1 {
    font-family: Verdana,sans-serif;
    font-size: clamp(2rem, 3vw, 6rem);
    margin-bottom: 20px;
    opacity: 0;
}

.content p {
    font-family: inherit;
    font-size: 1.2rem;
    line-height: 1.4;
    font-weight: 500;
    opacity: 0;
    text-align: left;
}

.swiper-slide-active .content {
    opacity: 1;
}

.swiper-slide-active .content h1 {
    animation: moveDown 0.8s ease-in forwards;
}

.swiper-slide-active .content p {
    animation: moveDown 1s ease-in forwards;
    animation-delay: 1s;
}

@keyframes moveDown {
    0% {
        transform: translateY(-20px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

/* background */

.background[data-item="one"] {
    background: url('./assets/about.png');
    background-size: cover;
    background-position: 50% 40%;
}

.background[data-item="two"] {
    background: url('./assets/about.jpg');
    background-size: cover;
    background-position: 50% 50%;
}

.background[data-item="three"] {
    background: url(https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/4e3edcf1-1b68-408f-87e2-ee9fd477bf87);
    background-size: cover;
    background-position: 50% 40%;
}

.background[data-item="four"] {
    background: url(https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/b772933f-6340-4c95-8d06-d809c4c9b739);
    background-size: cover;
    background-position: 50% 60%;
}

.background[data-item="five"] {
    background: url(https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/9f0fab6b-c28d-47e9-80f0-868e00562c3f);
    background-size: cover;
    background-position: 50% 40%;
}

.background {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    clip-path: circle(5%);
    filter: brightness(90%);
    overflow: hidden;
    opacity: 0;
    z-index: 1;
}

.animation {
    animation: resizeClipPath 3s ease-in-out forwards;
}

@keyframes resizeClipPath {
    0% {
        clip-path: circle(5%);
        opacity: 0;
    }
    100% {
        clip-path: circle(71%);
        opacity: 1;
    }
}

/* pagination bullet */

.swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    background-color: #a3a6a3;
    border-radius: 50%;
    transition: all 0.6s ease-in-out;
}

.swiper-pagination-bullet-active {
    height: 32px;
    background-image: linear-gradient(180deg, #f09819 0%, #ff512f 100%);
    border-radius: 14px;
}

/* footer */

.footer {
    position: absolute;
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    bottom: 0;
    left: 50%;
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    width: max-content;
    padding: 20px;
    color: #f2f2f2;
    border-radius: 8px 8px 0 0;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    z-index: 2;
}

.feature {
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.feature i {
    font-size: 1.4rem;
}

.feature p {
    font-weight: 700;
}

.btn {
    display: block;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    padding: 15px 20px;
    border: 0;
    border-radius: 10px;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(
            45deg,
            #ff512f 0%,
            #f09819 51%,
            #ff512f 100%
    );
    background-size: 200% auto;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    transition: 0.5s;
}

.btn:hover {
    background-position: right center;
}

.btn:active {
    transform: scale(0.95);
}

.logo {
    position: fixed;
    right: -20px;
    bottom: -35px;
    z-index: 10;
}

.logo img {
    width: 120px;
}

@media (max-width: 890px) {
    .logo {
        right: -10px;
        bottom: -20px;
    }

    .logo img {
        width: 80px;
    }
}

/* media queries */

@media (max-width: 1200px) {
    .content {
        top: 18%;
    }

    .content[data-content="two"],
    .content[data-content="three"] {
        top: 5%;
        width: 50%;
    }
}

@media (max-width: 900px) {
    .content,
    .content[data-content="three"] {
        top: 55%;
        left: 2%;
        width: 60%;
    }

    .content[data-content="two"] {
        top: 10%;
    }

    .content h1 {
        margin-bottom: 14px;
    }

    .content p {
        font-size: 1rem;
        line-height: 1.4;
    }

    .feature i {
        font-size: 1.3rem;
    }

    .feature p {
        font-size: 1rem;
    }

    .btn {
        padding: 8px 16px;
    }
}

@media (max-width: 790px) {
    .footer {
        column-gap: 10px;
    }

    .feature i {
        font-size: 1.1rem;
    }

    .feature p {
        font-size: 0.9rem;
    }

    .feature small {
        font-size: 0.8rem;
    }

    .btn {
        font-size: 0.8rem;
        padding: 8px 12px;
    }
}

@media (max-width: 660px) {
    .content,
    .content[data-content="two"],
    .content[data-content="three"] {
        top: unset;
        left: 2%;
        bottom: 3%;
        width: 80%;
    }

    .content p {
        font-size: 0.9rem;
        line-height: 1.2;
    }

    .background[data-item="two"] {
        background-position: 40% 50%;
    }

    .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
    }

    .swiper-pagination-bullet-active {
        height: 24px;
    }

    .footer {
        column-gap: 0;
        left: unset;
        bottom: unset;
        top: 20px;
        right: -30px;
        padding: 0;
        border-radius: 0;
        border: 0;
        background-color: transparent;
        box-shadow: unset;
        -webkit-backdrop-filter: blur(0);
        backdrop-filter: blur(0);
    }

    .feature,
    hr {
        display: none;
    }
}

.typed-cursor {
    display: none;
  }

  canvas {
    background: none;
    border: none !important;
  }


  @keyframes backgroundZoom {
    0% {
      background-size: 100%;
    }
    100% {
      background-size: 110%;
    }
  }

  .background-zoom {
    animation: backgroundZoom 10s ease-in-out forwards;
  }


  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-40px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1; 
    }
  }
  
  .animate-slideIn {
    animation: slideInFromLeft 4s;
  }

  @keyframes slideUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.slide-up {
    animation: slideUp 1s ease-out forwards;
}





#projects {
    background-color: #2f3246;
    min-height: 93vh;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  

  #projects .max-w-screen-2xl {
    padding: 2rem 1rem;
    max-width: 1280px;
    margin: 0 auto;
  }
  
 
  #projects h2 {
    font-size: 2rem;
    line-height: 1.4;
    color: #ffffff;
  }
  
  @media (min-width: 640px) {
    #projects h2 {
      font-size: 2.5rem;
    }
  }
  

  .carousel-container {
    margin-top: 4rem;
    width: 100%;
    padding: 0 1rem;
  }
  
 
  .carousel-container .space-y-4 {
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    padding: 1.25rem;
    background-color: #ffffff;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  

  .carousel-container img {
    width: 100%;
    height: 34vh;
    object-fit: cover;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  

  .carousel-container h3 {
    font-size: 1.25rem;
    font-weight: bold;
    color: #2f3246;
  }
  
  .carousel-container p {
    font-size: 1rem;
    color: #656a8d;
    line-height: 1.5;
  }
  
  @media (max-width: 768px) {
    #projects {
      padding: 1rem;
    }
  
    #projects .max-w-screen-2xl {
      padding: 1rem;
    }
  
    .carousel-container .space-y-4 {
      padding: 1rem;
      height: 50vh;
    }
  
    .carousel-container img {
      height: 25vh;
    }
  
    .carousel-container h3 {
      font-size: 1.1rem;
    }
  
    .carousel-container p {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .carousel-container .space-y-4 {
      height: 45vh;
      padding: 0.75rem;
    }
  
    .carousel-container img {
      height: 20vh;
    }
  
    .carousel-container h3 {
      font-size: 1rem;
    }
  
    .carousel-container p {
      font-size: 0.85rem;
    }
  }


  @keyframes slideInLeft {
    0% {
      transform: translateX(-100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slideInRight {
    0% {
      transform: translateX(300px);
      transform: translateY(450px);
      /* opacity: 0; */
    }
    100% {
      transform: translateX(0);
      /* opacity: 1; */
    }
  }
  
  .top-shape {
    background-image: url('./assets/top-shape-2.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top left;
    width: 355px;
    height: 240px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    animation: slideInLeft 1s ease-in-out 0s;
  }
  
  .bottom-shape {
    background-image: url('./assets/bottom-shape-2.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom right;
    width: 480px;
    height: 571px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    animation: slideInRight 1s ease-in-out 0s;
  }
  
















